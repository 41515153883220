import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-button-renderer',
  /* template: `<button class="{{btnClass}}" type="button" (click)="onClick($event)">{{label}}</button>` */
  template: `<a href="javascript:void(0)" (click)="onClick($event)">{{label}}</a>`
})

export class ButtonRendererComponent implements ICellRendererAngularComp {

  params;
  label: string;
  btnClass : string;

  agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;
    this.btnClass = this.params.btnClass || 'btn btn-primary';
  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.onClick(params);

    }
  }
}