import { Component, OnInit, Input } from '@angular/core';
import { SdsserviceService } from 'src/app/core/shared/sdsservice.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { socialLinks } from 'src/app/core/models/SocialLinks';

@Component({
  selector: 'app-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.css']
})
export class SocialComponent implements OnInit {

  @Input() SocialLinksList: socialLinks[] = [];
  urlSafe : SafeResourceUrl;

  constructor(private service: SdsserviceService, public sanitizer : DomSanitizer) { }

  ngOnInit() {
  }

  getSrc(guid:any)
  {
    const url = this.SocialLinksList.filter(s => s.Guid == guid)[0].Name;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    return this.urlSafe;
  }

}
