export class TeamMembers{
    Guid: any;
    Name : string;
    DesignationGuid :any;
    Designation:string;
    EmailID:string;
    Description:string;
    Image:string;
    OrderBy : number;
    CreatedAt : Date;
}