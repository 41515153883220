import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'sds';
  template: string =`<img class="custom-spinner-template" src="../assets/images/loader.gif">`
}
