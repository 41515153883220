import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SdsserviceService {

  constructor(private http: HttpClient) { }

  get(resource: string): Observable<any[]> {
    return this.http.get<any[]>(environment.API_URL + '/api/' + resource);
  }

  getPromise(resource: string) {
    return this.http.get<any[]>(environment.API_URL + '/api/' + resource);
  }

  Save(resource: string, obj: any): Observable<any> {
    
    let httpheaders = new HttpHeaders().set('Content-Type', 'application/json');

    let options = {
      headers: httpheaders
    }
    if (obj.GUID == undefined || obj.GUID == '') {
      return this.http.post<any>(environment.API_URL + '/api/' + resource, obj, options);
    }
    else {
      return this.http.put<any>(environment.API_URL + '/api/' + resource + '/' + obj.GUID, obj, options);
    }
  }

  ApplyJob(resource: string, obj: any)
  {
    return this.http.post<any>(environment.API_URL + '/api/' + resource, obj);
  }

  downloadFile(resource: string,obj : any) {  
    return this.http.get(environment.API_URL + '/api/' + resource + '/' + obj.Guid, { responseType: "blob" });  
     
  }  
}
