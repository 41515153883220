import { Component, OnInit, Input } from '@angular/core';
import { ProjectDTO } from 'src/app/core/models/projects';
import { SdsserviceService } from 'src/app/core/shared/sdsservice.service';
import { environment } from 'src/environments/environment';

export interface ProjectList {
  imageName: string;
  projectName: string;
  description: string;
  theme: string;
}

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})

export class ProjectsComponent implements OnInit {
  projectList1: ProjectList[] = [
    {
      imageName: "biglogo.png",
      projectName: "Helpage India",
      description: "Incubating and managing micro enterprises for elderly people under Helpage India programme to support elderly people in Bihar",
      theme: "Livelihood, Micro enterprise and Social Welfare"
    },
    {
      imageName: "biglogo.png", 
      projectName: "Project Concern International (PCI)", 
      description: "Training of Group Leaders on Integrated Health, Nutrion and Sanitaon under Parivartan project across 2 blocks of Khagaria & 1 block of Begusarai districts", 
      theme: "Health & Nutrion and Sanitaon"
    },
    {
      imageName: "biglogo.png",
      projectName: "Aga Khan Foundation (AKF)",
      description: "Awareness campaign for BUNIYAD Project across four blocks of Samastipur and Muzaffarpur districts of Bihar",
      theme: "Women & Child Development and Nutrition"
    },
    {
      imageName: "biglogo.png",
      projectName: "Madhya Bihar Gramin Bank (MBGB)",
      description: "Awareness campaign on Jan Dhan Yojna for financial inclusion and digital payments through puppet shows and audio visuals across 6 districts of Bihar",
      theme: "Financial Inclusion"
    },
    {
      imageName: "biglogo.png",
      projectName: "Rajiv Gandhi National Institute of Youth Development (RGNIYD)",
      description: "Training on career counselling for the Scheduled Tribe youth in 3 districts of Jharkhand",
      theme: "Counselling and Social Welfare"
    },
    {
      imageName: "biglogo.png",
      projectName: "HDFC Bank Ltd.",
      description: "Technical training of women entrepreneurs across various trades under “HUNAR” (Sustainable Livelihood Initiative) programme of HDFC Bank",
      theme: "Livelihood and Women empowerment"
    },
    {
      imageName: "biglogo.png",
      projectName: "National Bank For Agriculture And Rural Development (NABARD)",
      description: "Awareness campaign on financial literacy across 12 blocks of East Champaran district of Bihar",
      theme: "Financial Inclusion"
    },
    {
      imageName: "biglogo.png",
      projectName: "Chhattisgarh Rajya Gramin Bank (CRGB)",
      description: "Awareness campaign on financial inclusion under dFLA programme in Chhattisgarh through puppet shows and audio visuals",
      theme: "Financial Inclusion"
    }
  ];

  projectList2: ProjectList[] = [
    
    {
      imageName: "biglogo.png",
      projectName: "HDFC Bank Ltd.",
      description: "Placement linked skill development of 1,000 economically weaker youths across retail, hospitality, IT/ ITeS sectors in Raipur district of Chhattisgarh ",
      theme: "Placement linked Skill Development"
    },
    {
      imageName: "biglogo.png",
      projectName: "National Bank For Agriculture And Rural Development (NABARD)",
      description: "Formation & handholding of two Farmers’ Producer Organizations (FPOs) in Bhojpur and Patna districts of Bihar",
      theme: "Agriculture and Farmers’ welfare"
    },
    {
      imageName: "biglogo.png",
      projectName: "Summit Corporation",
      description: "Construction of Leach Pit under the CSR of NTPC at Bhojpur & Dhaniyawa, Patna Bihar",
      theme: "Sanitation"
    },
    {
      imageName: "biglogo.png",
      projectName: "Bihar Livelihood Promotion Society (BRLPS)",
      description: "Placement linked skill development of rural youths under Deen Dayal Upadhyaya Grameen Kaushalya Yojana (DDU-GKY) across 3 districts of Bihar",
      theme: "Placement linked Skill Development"
    },
  ];

  projectList3: ProjectList[] = [
    {
      imageName: "biglogo.png",
      projectName: "Ernst & Young LLP (EY)",
      description: "District Program Management Unit (DPMU) under Bihar Skill Development Mission (BSDM) across 38 districts of Bihar",
      theme: "Skill Development"
    },
    {
      imageName: "biglogo.png",
      projectName: "Society for Sustainable Action for Learning (S-ACT)",
      description: "Status of overall sanitation for two districts of Bihar under Nirmal Bharat Abhiyan",
      theme: "Water & Sanitation"
    },
    {
      imageName: "biglogo.png",
      projectName: "Ernst & Young LLP (EY)",
      description: "District Program Management Unit (DPMU) under Jharkhand Skill Development Mission Society (JSDMS) across all 24 districts of Jharkhand",
      theme: "Skill Development"
    },
    {
      imageName: "biglogo.png",
      projectName: "Ernst & Young LLP (EY)",
      description: "Technical resource Agency (TRA) for CLTS in 62 Gram Panchayats across selected blocks in Araria and Begusarai districts of Bihar",
      theme: "Sanitation"
    }
  ];

  projectList4: ProjectList[] = [
    {
      imageName: "biglogo.png",
      projectName: "The World Bank",
      description: "Technical support in kitchen garden development for Jeevika Multispectral Nutrition Project in Saur Bazar block of Saharsa district of Bihar.",
      theme: "Nutrition and Horticulture"
    },
    {
      imageName: "biglogo.png",
      projectName: "PricewaterhouseCoopers Pvt Ltd (PwC)",
      description: "Skill gap analysis through primary data collection from key stakeholders in SAS Nagar (Mohali) and Jalandhar districts of Punjab",
      theme: "Skill Development"
    },
    {
      imageName: "biglogo.png",
      projectName: "University of WarwicK",
      description: "Conduct Study on Determinants of Participation in Skill Training Programs and its Impact on employment and wages",
      theme: "Skill Development"
    },
    {
      imageName: "biglogo.png",
      projectName: "Bihar Voluntary Health Association (BVHA)",
      description: "Base line survey on menstrual hygiene management in Munger district of Bihar",
      theme: "Reproductive Child Health"
    },
    {
      imageName: "biglogo.png",
      projectName: "Aga Khan Rural Support Programme India (AKRSPI)",
      description: "Diagnostic study of SHGs and CBSGs promoted by AKRSPI in Muzaffarpur and Samastipur districts of Bihar.",
      theme: "Livelihood and Community institutions"
    },
    {
      imageName: "biglogo.png",
      projectName: "Confederation of Indian Industry (CII)",
      description: "Farmers’ baseline study for the project 'Transforming Eastern India’s Economies through Innovative Rural Business in the states of Bihar and West Bengal",
      theme: "Agri Entrepreneurship"
    },
    {
      imageName: "biglogo.png",
      projectName: "Malaysia Institute for Supply Chain Innovation (MISI)",
      description: "Agriculture value chain study of potential crops in Patna & Nalanda districts of Bihar and Nadia and 24- South Parganas districts of West Bengal",
      theme: "Agriculture"
    },
    {
      imageName: "biglogo.png",
      projectName: "Bihar Mahila Samakhya Society (BMSS)",
      description: "Technical support for conducting social mapping & FGD’s across 200+ villages of Mohanpur block of Gaya district of Bihar.",
      theme: "Women & Child Development"
    },
  ];
  
  baseURL = environment.API_URL;
  constructor(private service: SdsserviceService) { }
 @Input()  projectDTO : ProjectDTO[];
  ngOnInit() {
  }
}
