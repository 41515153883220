import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SdsserviceService } from 'src/app/core/shared/sdsservice.service';
import { GridApi, ColumnApi, GridOptions } from 'ag-grid-community';
import { Router } from '@angular/router';
import { ButtonRendererComponent } from 'src/app/core/shared/button-renderer.component';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
declare var require: any;
declare var scrollToTopFn: any;

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.css']
})
export class ResourcesComponent implements OnInit {
  objs: any;
  selectedRowData :any;
  frameworkComponents: any;

  private api: GridApi;
  private columnApi: ColumnApi;
  public gridOptions: GridOptions;
  columnDefs = [
    { headerName: 'Guid', field: 'Guid', filter: 'agNumberColumnFilter', cellClass: 'number-cell', pinned: true, width: 70, hide: true },
    { headerName: 'Name', field: 'Name' },
    { headerName: 'Description', field: 'Description'},
    { headerName: 'Uploded At', field: 'CreatedDate'},
    {
      headerName: 'Download',
      cellRenderer: 'buttonRenderer',
      cellRendererParams: {
        onClick: this.onSelectedRow.bind(this),
        label: 'Click Here',
        btnClass: 'highlight-button-dark btn btn-small button'
      }
    }];

  rowData = [{}];


  constructor(private service: SdsserviceService, private spinnerService: Ng4LoadingSpinnerService, private router: Router) {
    this.gridOptions = <GridOptions>{
      context: this,
      columnDefs: this.columnDefs,
      rowSelection: 'single',
      rowHeight: 32,
      deltaRowDataMode: true,
      enableColResize: true,
      enableSorting: false,
      animateRows: false,
      pagination: true,
      paginationPageSize: 10,
      enableFilter: false,
      floatingFilter: false,
      /* onSelectionChanged: (event) => this.onSelectionChanged(), */
      getRowNodeId: function (params) {
        return params.Guid;
      }
    };

    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    }
  }

  ngOnInit() {
    this.getFiles();
    scrollToTopFn();
  }


  private onGridReady(params): void {
    this.api = params.api;
    this.columnApi = params.columnApi;
    this.api.sizeColumnsToFit();
    window.onresize = () => {
      this.api.sizeColumnsToFit();
    }
  }

  onSelectedRow(e) {
    this.selectedRowData = e.rowData;

    this.service.downloadFile('UploadFiles/DownloadFile',this.selectedRowData).subscribe(response=>{
      if (response.type != 'text/plain') {  
        var blob = new Blob([response]);  
        let saveAs = require('file-saver');  
        let file = this.selectedRowData.FileName;  
        saveAs(blob, file);  
      }  
      else {  
        alert('File not found in Blob!');  
      }  
    });
  }

  getFiles() {
    this.spinnerService.show();
    this.service.get('UploadFiles').subscribe((data: {}) => {
      this.objs = data as any;
      this.rowData = this.objs;
      this.spinnerService.hide();
    },
      (error) => {
        this.spinnerService.hide();
      });
  }
}
