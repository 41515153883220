import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ContactUs } from 'src/app/core/models/contactus';
import { SdsserviceService } from 'src/app/core/shared/sdsservice.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  contactUsForm: FormGroup;
  obj: ContactUs = new ContactUs();
  submitted = false;
  ContactDetail : any[];
  HOAddress : any;
  HOPH: any;
  HOEmail:any;
  RanchiAddress : any;
  PatnaAddress:any;
  RaipurAddress : any;
  NoidaAddress: any;
  OrissaAddress : any;
 
  constructor(private service: SdsserviceService, private fb: FormBuilder, private router: Router) { 
    this.GetContactDetail();
  }

  ngOnInit() {
    this.contactUsForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobile: ['', [Validators.required, Validators.maxLength(10)]],
      message: ['', [Validators.required, Validators.maxLength(500)]]
    });

    
    
    
  }

  GetContactDetail() {
    this.service.get("ContactDetail").subscribe(result => {
      let objs:any;
      objs = result as any;
      this.ContactDetail = objs;

      this.ContactDetail.forEach(a=>{
        if(a.Guid == '5dcca80a-a29b-4a68-9392-6ee37e5c305c')
        {
          this.HOAddress = a.Address
          this.HOPH = a.Mobile;
          this.HOEmail = a.EmailID
        }

        if(a.Guid =='794808d5-3331-4a09-9d20-398cd38a3db2')
        {
          this.RanchiAddress = a.Address
        }

        if(a.Guid =='eb24e4e6-4ab3-4331-85d7-b8f8374dfe2b')
        {
          this.PatnaAddress = a.Address
        }

        if(a.Guid =='924344dc-31e8-4515-9ebb-2736ef9b600e')
        {
          this.RaipurAddress = a.Address
        }

        if(a.Guid =='f680d5dd-be2d-4d2e-98f8-a2b0dd9835e5')
        {
          this.NoidaAddress = a.Address
        }

        if(a.Guid =='f701a45d-8c78-4200-a006-30240562100d')
        {
          this.OrissaAddress = a.Address
        }
      });
    }); 
  }

  // convenience getter for easy access to form fields
  get f() { return this.contactUsForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.contactUsForm.invalid) {
      return;
    }

    this.service.Save('Apply/Inquiry', this.obj).subscribe((data: {}) => {
      alert('Saved Successfully !!');
      this.contactUsForm.reset();
      
    });
  }
}
