import { Component, OnInit, Input } from '@angular/core';
import { SdsserviceService } from 'src/app/core/shared/sdsservice.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-our-approach',
  templateUrl: './our-approach.component.html',
  styleUrls: ['./our-approach.component.css']
})
export class OurApproachComponent implements OnInit {

  Messages: string[];
  @Input() ourApproachHeader : string;
  @Input() ourApproachObjs : any[];
  constructor(private service: SdsserviceService,private spinnerService: Ng4LoadingSpinnerService) {
    
   }

  ngOnInit() {
    //this.animateSection();
    this.GetOurApproach();
  }

  animateSection(){
    let arr = ['one', 'two', 'three', 'four', 'five', 'six'];
    let counter = 0;
    setInterval(() => {
      if(counter < 6){
        this.showMessage(arr[counter]);
        counter++;
      }
      else{
        counter = 0;
      }
      setTimeout(() => {
        this.hideMessage();
      }, 1000);
    }, 2000);
  }

  showMessage(val: string) {
    
    this.Messages = [];
    document.querySelector('.popover-message').classList.add("show");
    document.querySelector(`.${val}`).classList.add('highlight');
    switch (val) {
      case 'one':
        //this.Messages.push('Consultation', 'Background Research', 'Matrix for situation analysis');
        this.ourApproachObjs.filter(s=>s.TypeGuid == '831e0f2d-8e61-4181-bba2-b6d41e368842').forEach(obj=>{
          this.Messages.push(obj.Name);
        });
        break;
      case 'two':
        //this.Messages.push('Benchmarking', 'Scenario Analysis', 'Resource Mapping');
        this.ourApproachObjs.filter(s=>s.TypeGuid == '6f3b01b3-699a-44a8-8f45-99bd30b06aa7').forEach(obj=>{
          this.Messages.push(obj.Name);
        });
        break;
      case 'three':
        //this.Messages.push('Industry Best Practices', 'Leveraging Networks', 'Domain Expertise');
        this.ourApproachObjs.filter(s=>s.TypeGuid == '02448d96-28ce-4593-84ea-dfc605e1c84f').forEach(obj=>{
          this.Messages.push(obj.Name);
        });
        break;
      case 'four':
        //this.Messages.push('Testing in Controlled Environment', 'Association with Academia');
        this.ourApproachObjs.filter(s=>s.TypeGuid == 'db77cf62-3260-4091-8f04-35e13f4a5673').forEach(obj=>{
          this.Messages.push(obj.Name);
        });
        break;
      case 'five':
        //this.Messages.push('Engaging Experts', 'Network on Ground', 'Success Factors', 'MEL Structure');
        this.ourApproachObjs.filter(s=>s.TypeGuid == 'ee9326ae-1090-40c7-8846-b0a74f4be061').forEach(obj=>{
          this.Messages.push(obj.Name);
        });
        break;
      case 'six':
        //this.Messages.push('Exit strategy', 'Knowledge/ Process Management');
        this.ourApproachObjs.filter(s=>s.TypeGuid == '56635b02-d583-4616-96eb-3bf6a7ef0e2b').forEach(obj=>{
          this.Messages.push(obj.Name);
        });
        break;
    }
  }

  hideMessage() {
    document.querySelector('.popover-message').classList.remove("show");
    document.querySelectorAll('svg g g').forEach((item) => {
      item.classList.remove('highlight');
    });
  }

  GetOurApproach()
  {
    this.spinnerService.show();
    this.service.get("OurApproach").subscribe(result => {
      this.ourApproachObjs = result as any;
      this.spinnerService.hide();
    });
  }
}
