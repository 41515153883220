import { Component, OnInit, Input } from '@angular/core';

export interface ServiceList {
  imgName: string;
  serviceName: string;
}

@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.css']
})
export class OurServicesComponent implements OnInit {
  Service: ServiceList[];
  @Input() serviceHeader : string;
  constructor() { 

    
  }

  ngOnInit() {
    
  }

  showServices(val: any) {
    this.Service = [];
    document.querySelector('.service-message').classList.add("show");
    document.querySelector(`.${val}`).classList.add('highlight');
    switch (val) {
      case 'S1':
        this.Service.push(
          { imgName: 's11', serviceName: 'Communication Strategy' },
          { imgName: 's12', serviceName: 'IEC Collateral' },
          { imgName: 's13', serviceName: '360 &deg; Awareness Campaign' },
          { imgName: 's14', serviceName: 'Puppet Show/ Nukkad Natak' },
          { imgName: 's15', serviceName: 'Training Design and Content' },
          { imgName: 's16', serviceName: 'Community Training' },
        );
        break;
      case 'S2':
        this.Service.push(
          { imgName: 's21', serviceName: 'Micro-planning through participatory approaches' },
          { imgName: 's22', serviceName: 'Data collection and analysis' },
          { imgName: 's23', serviceName: 'Report writing/ Documentation' },
          { imgName: 's24', serviceName: 'Monitoring and Evaluation' },
        );
        break;
      case 'S3':
        this.Service.push(
          { imgName: 's31', serviceName: 'Project plan and strategy' },
          { imgName: 's32', serviceName: 'Pilot testing and roll out ' },
          { imgName: 's33', serviceName: 'Project implementation across social development sectors' },
        );
        break;
      case 'S4':
        this.Service.push(
          { imgName: 's41', serviceName: 'Recruitment and stafﬁng of professionals' },
          { imgName: 's42', serviceName: 'Project management unit' },
          { imgName: 's43', serviceName: 'Payroll Management' },
        );
        break;
    }
  }

  hideServices(val:string) {
    document.querySelector('.service-message').classList.remove("show");
    document.querySelector(`.${val}`).classList.remove('highlight');
  }

  gotoServices(){
    //window.location.href = "/#/gallery";
    window.open('/#/services', '_blank');
  }

}
