import { Component, OnInit } from '@angular/core';
import { SdsserviceService } from 'src/app/core/shared/sdsservice.service';
import { TeamMembers } from 'src/app/core/models/TeamMembers';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

export interface TeamList {
  name: string;
  designation: string;
  fileName: string;
  profile:string;
}

@Component({
  selector: 'app-our-team',
  templateUrl: './our-team.component.html',
  styleUrls: ['./our-team.component.css']
})
export class OurTeamComponent implements OnInit {

  teamList1: TeamList[] = [
    { name: "Shikha Agrawal", designation: "Senior Manager", fileName: "shikha-agarwal.jpg", profile: "profile" },
    { name: "Saket Kumar", designation: "Senior Manager", fileName: "saket-kumar.jpg", profile: "profile" },
    { name: "Rohit Kumar Gupta", designation: "Project Manager", fileName: "rohit-kumar.jpg", profile: "profile" },
    { name: "Pratik Goel", designation: "Project Manager", fileName: "pratik-goel.jpg" , profile: "profile"},
    { name: "Amit Kumar", designation: "Project Officer", fileName: "amit-kumar.jpg", profile: "profile" },
    { name: "Saubia Arshi", designation: "Project Officer", fileName: "saubia-arshi.jpg", profile: "profile" },
    { name: "Anamita Mitra", designation: "Officer Fund Raising & Strategy", fileName: "anamita-mitra.jpg", profile: "profile" },
    { name: "Purnima Kumari", designation: "Project Coodinator", fileName: "purnima-kumari.jpg", profile: "profile" },
    { name: "Ravi Shankar Kumar", designation: "Accountant", fileName: "ravishankar-kumar.jpg", profile: "profile" },
    { name: "Vishal Ranjan", designation: "Admin Officer", fileName: "vishal-ranjan.jpg", profile: "profile" },
    { name: "Ashutosh Kumar", designation: "Office Executive", fileName: "ashutosh-kumar.jpg" , profile: "profile"}
  ];

  teamList2: TeamList[] = [
    { name: "Abhijeet Kumar", designation: "District Consultant-Trainning, Giridih", fileName: "abhijeet-kumar.jpg", profile: "profile" },
    { name: "Ajay Kumar Sharma", designation: "District Consultant-Trainning, Garhwa", fileName: "ajay-kumar-sharma.jpg", profile: "profile" },
    { name: "Amit Kashyap", designation: "Data Entry Operator, Raipur", fileName: "amit-kashyap.jpg", profile: "profile" },
    { name: "Amit Kumar Singh", designation: "District Consultant-Trainning, Ranchi", fileName: "amit-kumar-singh.jpg", profile: "profile" },
    { name: "Ashutosh Chaurasia", designation: "District Consultant-Trainning, Dhanbad", fileName: "ashutosh-chaurasia.jpg" , profile: "profile"},
    { name: "Avjit Kumar Roy", designation: "District Consultant-Trainning, Koderma", fileName: "avjit-kumar-roy.jpg", profile: "profile" },
    { name: "Brajesh Kumar", designation: "District Consultant-Trainning, Gumla", fileName: "brajesh-kumar.jpg", profile: "profile" },
    { name: "Dharmesh Kumar", designation: "District Consultant-Trainning, Simdega", fileName: "dharmesh-kumar.jpg", profile: "profile" },
    { name: "Digen Prasad Sah", designation: "District Consultant-Trainning, Godda", fileName: "digen-prasad-sah.jpg", profile: "profile"},
    { name: "Durgesh Kumar Singh", designation: "District Consultant-Trainning, Jamtara", fileName: "durgesh-kumar-singh.jpg" , profile: "profile"},
    { name: "Harsh Kumar Tiwari", designation: "District Consultant-Trainning, Chatra", fileName: "harsh-kumar-tiwari.jpg", profile: "profile" },
    { name: "Harshwardhan Tarak", designation: "Data Entry Operator, Raipur", fileName: "harshwardhan-tarak.jpg" , profile: "profile"},
  ];

  teamList3: TeamList[] = [
    { name: "Abhishek Kumar", designation: "District Skill Manager, Araria", fileName: "abhishek-kumar.jpg", profile: "profile" },
    { name: "Abhishek Kumar Sinha", designation: "District Skill Manager, Patna", fileName: "abhishek-kumar-sinha.jpg", profile: "profile" },
    { name: "Abhishek Prasad", designation: "District Skill Manager, Patna", fileName: "abhishek-prasad.jpg" , profile: "profile"},
    { name: "Ajay Kumar", designation: "District Skill Manager, Siwan", fileName: "ajay-kumar.jpg" , profile: "profile"},
    { name: "Amit Kumar Singh", designation: "District Skill Manager, Patna", fileName: "amit-kumar-singh.jpg" , profile: "profile"},
    { name: "Animesh Kumar", designation: "District Skill Manager, Rohtas", fileName: "animesh-kumar.jpg" , profile: "profile"},
    { name: "Avinash Kumar", designation: "District Skill Manager, Siwan", fileName: "avinash-kumar.jpg" , profile: "profile"},
    { name: "Bharat Bhusan", designation: "District Skill Manager, Jehanabad", fileName: "bharat-bhusan.jpg" , profile: "profile"},
    { name: "Bindhyachal Kumar", designation: "District Skill Manager, Begusarai", fileName: "bindhyachal-kumar.jpg", profile: "profile" },
    { name: "Dinesh Kumar", designation: "District Skill Manager, Sitamarhi", fileName: "dinesh-kumar.jpg", profile: "profile"},
    { name: "Gajendra Yadav", designation: "District Skill Manager, Kishanganj", fileName: "gajendra-yadav.jpg" , profile: "profile"},
    { name: "Kanhaiya Kumar", designation: "District Skill Manager, Muzaffarpur", fileName: "kanhaiya-kumar.jpg" , profile: "profile"},
  ];
  
  baseURL = environment.API_URL;
  TopImage : TeamMembers=new TeamMembers();
  TeamMembers : TeamMembers[];

  constructor(private service: SdsserviceService,private router : Router,private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {
    this.getTeamMembers();
  }

  getTeamMembers()
  {
    this.spinnerService.show();
    this.service.get("TeamMembers/GetTeamMenbers?Position=H").subscribe(result => {
      this.TeamMembers  = result as any;
      this.TopImage = this.TeamMembers.filter(obj => obj.Guid == '2800121e-410a-451e-a88e-de530470a798')[0];
      this.TeamMembers = this.TeamMembers.filter(obj => obj.Guid != '2800121e-410a-451e-a88e-de530470a798');
      this.spinnerService.hide();
    });
    
  }

  navigateAssociates() {
    //window.location.href = "/#/team";
    window.open('/#/team', '_blank');
  }

 
}
