import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SdsserviceService } from 'src/app/core/shared/sdsservice.service';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.css']
})
export class TestimonialsComponent implements OnInit {

  baseURL = environment.API_URL;
  //TestimonialList: string[] = [];
  
  constructor(private service: SdsserviceService) { }

  @Input() TestimonialList: string[];

  ngOnInit() {
    //this.getTestimonial();
  }

 

}
