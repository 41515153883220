import { Component, OnInit } from '@angular/core';
import '../../../assets/js/custom.js';

declare var scrollToElement: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  scrollElement(val: string, offsetVal: number) {
    scrollToElement(val, offsetVal);
  }

  gotoResources(){
    //this.router.navigateByUrl("/gallery");
    //window.location.href = "/#/resources";
    window.open('/#/resources', '_blank');
  }

}
