import { Component, OnInit, OnDestroy } from '@angular/core';
import '../../../assets/js/custom.js';
import { Router, NavigationEnd } from '@angular/router';
import * as $ from 'jquery';
import { SdsserviceService } from 'src/app/core/shared/sdsservice.service.js';
import { environment } from 'src/environments/environment.js';
import { internalGalleryDTO, internalGalleryDetail } from 'src/app/core/models/InternalGalleryDTO.js';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { socialLinks } from 'src/app/core/models/SocialLinks.js';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';


declare var scrollToTopFn: any;
//declare var callLightBox: any;

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})

export class GalleryComponent implements OnInit  {

  SocialLinksList: socialLinks[] = [];
  baseURL = environment.API_URL;
  photoList: string[] = [];
  videoLinkList : internalGalleryDetail[];
  internalGalleryDTO: internalGalleryDTO[] = [];
  urlSafe : SafeResourceUrl;
  
  constructor(private service: SdsserviceService, public sanitizer : DomSanitizer,private spinnerService: Ng4LoadingSpinnerService) { 
    
  }

  ngOnInit() {
    
    this.getInternalGallery();
    //callLightBox();
    setTimeout(s=>{
      $.getScript('/assets/js/picturebox.js');
      scrollToTopFn();
    },2000);
    this.getSocialLinks();
    
   /*  this.photoList.push("cover page-1.jpg");
    this.photoList.push("cover page-2.jpg");
    this.photoList.push("cover page-3.jpg");
    this.photoList.push("cover page-4.jpg");
    this.photoList.push("cover page-5.jpg");
    this.photoList.push("cover page-6.jpg");
    this.photoList.push("cover page-7.jpg");
    this.photoList.push("cover page-8.jpg"); */
    
  }

  getInternalGallery()
  {
    this.spinnerService.show();
    this.service.get("InternalGallery").subscribe(result => {
      let objs:any[];
      this.internalGalleryDTO  = result as any;
      this.spinnerService.hide();
    },
    (error) => {
      this.spinnerService.hide();
    });
  }

  getSocialLinks()
  {
    this.service.get("SocialLinks").subscribe(result => {
      this.SocialLinksList  = result as any;
    });
  }

  /* getSrc(guid : any,iGuid : any)
  {
    this.videoLinkList = this.internalGalleryDTO.filter(s => s.Guid == guid)[0].InternalGalleryDetail.filter(a=>a.InternalGalleryGuid == guid && a.Type == 2);
    const url = this.videoLinkList.filter(s => s.Guid == iGuid)[0].Title;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    return this.urlSafe;
  } */

  getSrc(guid:any)
  {
    const url = this.SocialLinksList.filter(s => s.Guid == guid)[0].Name;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    return this.urlSafe;
  }

}
