export class currentPositionDetail{
    Lable : string;
    Detail : string;
    OrderBy : number;
}

export class currentPosition{
    AutoID:number;
    PositionCode:string;
    Position:string;
    PositionDetail : currentPositionDetail[];
}

