import { Component, OnInit } from '@angular/core';
import { ContactUs } from 'src/app/core/models/contactus';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SdsserviceService } from 'src/app/core/shared/sdsservice.service';
import { Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { currentPositionDetail } from 'src/app/core/models/currentOpenings';

@Component({
  selector: 'app-join-us',
  templateUrl: './join-us.component.html',
  styleUrls: ['./join-us.component.css']
})
export class JoinUsComponent implements OnInit {
  ApplyForForm: FormGroup;
  obj: ContactUs = new ContactUs();
  submitted = false;
  success: boolean = false;
  currentOpenings: currentPositionDetail = new currentPositionDetail();
  jobPosition : string;
  
  constructor(private service: SdsserviceService, private fb: FormBuilder, private router: Router, private spinnerService: Ng4LoadingSpinnerService, ) { }

  ngOnInit() {
    this.ApplyForForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobile: ['', [Validators.required, Validators.maxLength(10)]],
      message: ['', [Validators.required, Validators.maxLength(500)]],
      file: ['', Validators.required]
    });

    this.getCurrentOpenings();
  }

  // convenience getter for easy access to form fields
  get f() { return this.ApplyForForm.controls; }

  onSubmit(files, jobPosition) {
    this.submitted = true;
    if (this.ApplyForForm.invalid) {
      return;
    }

    this.submitted = true;
    this.spinnerService.show();
    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append('Name', this.obj.Name);
    formData.append('EmailID', this.obj.EmailID);
    formData.append('MobileNo', this.obj.MobileNo);
    formData.append('Message', this.obj.Message);
    formData.append('Type', jobPosition);

    this.service.ApplyJob("Apply/Apply", formData).subscribe(
      response => {
        this.success = true;
        this.spinnerService.hide();
        this.ApplyForForm.reset();
      }
    )
  }

  getCurrentOpenings()
  {
    this.service.get("CurrentOpening").subscribe(
      response => {
        this.currentOpenings = response as any;
      }
    )
  }
}
