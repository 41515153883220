import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { whoWeWorkForDTO } from 'src/app/core/models/WhoWeAorkFor';
import { SdsserviceService } from 'src/app/core/shared/sdsservice.service';
import { AwardDTO } from 'src/app/core/models/awards';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {

  ClientList1: string[] = [
    'nabard.jpg', 'jeevika-bihar.jpg', 'ddukgy.jpg', 'rgniyd.jpg', 'cg.jpg', 'crgb.gif', 'mbgb.jpg', 'nsdc.png', 'mssb.jpg'
  ];

  ClientList2: string[] = [
    'world-bank.jpg', 'aga-khan-foundation.jpg', 'pci.jpg', 'helpage-india.jpg', 'crs.jpg', 'warwick.png'
  ];

  ClientList3: string[] = [
    'bvha.jpg', 'corstone.png', 'cdot.png', 'kaushalya-foundation.png', 'idf.png', 'jjs.png',
    'msemvs.png', 'nidan.jpg', 'srhr.jpg', 'rahat.png', 'centre-direct.png', 'indian-nepal.jpg'
  ];

  ClientList4: string[] = [
    'ey.png', 'pwc.png', 'hdfc.jpg', 'cii.png', 'paytm.png',
    'sahaj.jpg', 'face.png', 'mgsn.jpg', 'misci.png', 'medica.png', 'mia.png', 'hcl.png'
  ];

  /* GalleryList: string[] = [
    '1', '2', '3', '4', '5', '6', '7', '8'
  ]; */

  baseURL = environment.API_URL;
  @Input() whoWeWorkForDTO : whoWeWorkForDTO[];
  GalleryList: string[] = [];
  @Input() awardDTO : AwardDTO[];

  constructor(private router: Router,private service: SdsserviceService,private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {
    //this.getWhoWeWorkFor();
    this.getGallery();
  }

  gotoGallery(){
    //window.location.href = "/#/gallery";
    window.open('/#/gallery', '_blank');
  }

  getGallery()
  {
    this.spinnerService.show();
    this.service.get("HomeGallery").subscribe(result => {
      this.GalleryList  = result as any;
      this.spinnerService.hide();
    });
  }

}
