import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-outreach',
  templateUrl: './outreach.component.html',
  styleUrls: ['./outreach.component.css']
})
export class OutreachComponent implements OnInit {

  address: string;
  
  @Input() outReachHeader : string;

  constructor() { }

  ngOnInit() {
  }

  showMessage(val: string) {
    this.address = '';
    document.querySelector('.address-message').classList.add("show");
    document.querySelector(`.${val}`).classList.add('highlight');
    switch (val) {
      /* case 'haryana':
        this.address = 'Gurugram';
        break;
      case 'chhatisgarh':
        this.address = "Raipur";
        break;
      case 'up':
        this.address = "Noida";
        break;
      case 'bihar':
        this.address = "Patna";
        break;
      case 'jharkhand':
        this.address = "Ranchi";
        break;
      case 'punjab':
        this.address = "Punjab";
        break;
      case 'orissa':
        this.address = "Orissa";
        break;
      case 'mp':
        this.address = "Madhya Pradesh";
        break;
      case 'wb':
        this.address = "West Bengal";
        break;
      case 'assam':
        this.address = "Assam"; */

        case 'haryana':
        this.address = 'Haryana';
        break;
      case 'chhatisgarh':
        this.address = "Chhattisgarh";
        break;
      case 'up':
        this.address = "Uttar Pradesh";
        break;
      case 'bihar':
        this.address = "Bihar";
        break;
      case 'jharkhand':
        this.address = "Jharkhand";
        break;
      case 'punjab':
        this.address = "Punjab";
        break;
      case 'orissa':
        this.address = "Odisha";
        break;
      case 'mp':
        this.address = "Madhya Pradesh";
        break;
      case 'wb':
        this.address = "West Bengal";
        break;
      case 'assam':
        this.address = "Assam";
    }
  }

  hideMessage() {
    document.querySelector('.address-message').classList.remove("show");
    document.querySelectorAll('svg .footprint').forEach((item) => {
      item.classList.remove('highlight');
    });
  }

}
