import { Component, OnInit } from '@angular/core';
import '../../../assets/js/custom.js';

declare var scrollToElement: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  scrollElement(val: string, offsetVal: number){
    scrollToElement(val, offsetVal);
  }

  gotoResources(){
    //this.router.navigateByUrl("/gallery");
    //window.location.href = "/#/resources";
    window.open('/#/resources', '_blank');

  }

}
