import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AwardDTO } from 'src/app/core/models/awards';

@Component({
  selector: 'app-awards',
  templateUrl: './awards.component.html',
  styleUrls: ['./awards.component.css']
})
export class AwardsComponent implements OnInit {

  baseURL = environment.API_URL;
  @Input() awardDTO : AwardDTO[];
  
  constructor() { }

  ngOnInit() {

  }

}
