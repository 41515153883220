import { Component, OnInit } from '@angular/core';
import '../../../assets/js/custom.js';
import { SdsserviceService } from 'src/app/core/shared/sdsservice.service.js';
import { environment } from 'src/environments/environment.js';
import { servicesDTO } from 'src/app/core/models/servicesDTO.js';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

declare var scrollToTopFn: any;

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  baseURL = environment.API_URL;
  photoList: string[] = [];
  servicesDTO: servicesDTO[] = [];
  constructor(private service: SdsserviceService, private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {
    this.getServices();
    scrollToTopFn();
  }

  getServices()
  {
    this.spinnerService.show();
    this.service.get("Services").subscribe(result => {
      let objs:any[];
      this.servicesDTO  = result as any;
      this.spinnerService.hide();
    },(error)=>{
      this.spinnerService.hide();
    });
  }
}
