import { Component, OnInit } from '@angular/core';
import '../../../assets/js/custom.js';
import { SdsserviceService } from 'src/app/core/shared/sdsservice.service.js';
import { ProjectDTO } from 'src/app/core/models/projects.js';
import { socialLinks } from 'src/app/core/models/SocialLinks.js';
import { ThrowStmt } from '@angular/compiler';
import { whoWeWorkForDTO } from 'src/app/core/models/WhoWeAorkFor.js';
import { AwardDTO } from 'src/app/core/models/awards.js';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

declare var scrollToTopFn: any;
declare var setSlider: any;
declare var countTimer: any;

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {

  jobPosition: string = 'Internship';
  mainHeader:string="MEANS ARE AS IMPORTANT AS ENDS";
  ourServicesHeader:string;
  outReachHeader:string;
  ourApproachHeader:string;
  aboutUs:string="Samvedna Development Society (SDS), has been working across ideation to implementation since 2011 with an endeavor to reach out to the last person of society through outcome based professional approaches. Committed to create people centric, sustainable and qualitative interventions, SDS works around capacity development, awareness, human capital development, effective project management and implementation. Our service offerings encompass all thematic areas of social and community development. We aspire to be known for our high level of professionalism, commitment and service delivery to our esteemed clients and donors.";
  ourApproachObjs : any[];
  ImpactingLives : any[];
  TestimonialList:string[]=[];
  projectDTO : ProjectDTO[];
  SocialLinksList: socialLinks[] = [];
  whoWeWorkForDTO : whoWeWorkForDTO[];
  contactDetailObjs : any[];
  awardDTO: AwardDTO[];

  constructor(private service: SdsserviceService,private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {
    scrollToTopFn();
    setSlider();

    this.getAllData();

    /* this.spinnerService.show();
    this.getWhoWeWorkFor();
    this.GetImpactingLives();
    this.GetHeaderDetail();
    this.GetAboutUs();
    this.getTestimonial();
    this.getProjects();
    this.getSocialLinks(); */



    /* this.getAward(); */

    /* setTimeout(() => {
      countTimer();
    }, 2000); */
    
    /* setTimeout(() => {
      this.getWhoWeWorkFor();
    this.GetImpactingLives();
    this.GetHeaderDetail();
    this.GetAboutUs();
    
    this.getTestimonial();
    this.getProjects();
    
    this.getSocialLinks();
    this.getAward();
    }, 5000); */
    

    

    //countTimer();
    /* setTimeout(() => {
      countTimer();
    }, 6000); */
  }

  getAllData()
  {
    this.spinnerService.show();
    this.service.getPromise("HeaderDetail").toPromise().then(result => {
      let objs:any[];
      objs = result as any;
      this.mainHeader = objs.filter(s=>s.HeaderType == 'Main')[0].Name;
      this.ourServicesHeader = objs.filter(s=>s.HeaderType == 'OurServices')[0].Name;
      this.outReachHeader = objs.filter(s=>s.HeaderType == 'Outreach')[0].Name;
      this.ourApproachHeader = objs.filter(s=>s.HeaderType == 'OurApproach')[0].Name;
      this.service.getPromise("AboutUs").toPromise().then(result => {
        let objs:any;
        objs = result as any;
        this.aboutUs = objs.Name;
        this.service.getPromise("Projects").toPromise().then(result => {
          this.projectDTO  = result as any;
          this.service.getPromise("ImpactingLives").toPromise().then(result => {
            let objs:any;
            objs = result as any;
            this.ImpactingLives = objs;
            this.service.getPromise("Testimonial").toPromise().then(result => {
              this.TestimonialList  = result as any;
              this.service.getPromise("WhoWeWorkFor").toPromise().then(result => {
                this.whoWeWorkForDTO  = result as any;
                this.service.get("Awards").subscribe(result => {
                  this.awardDTO  = result as any;
                  this.service.getPromise("SocialLinks").toPromise().then(result => {
                    this.SocialLinksList  = result as any;
                    this.spinnerService.hide();
                    countTimer();
                  },
                  (error) => {
                    this.spinnerService.hide();
                  });

                },
                (error) => {
                  this.spinnerService.hide();
                });
              },
              (error) => {
              this.spinnerService.hide();
              });
              
            },
            (error) => {
             this.spinnerService.hide();
            });

          },
          (error) => {
           this.spinnerService.hide();
          });

        },
        (error) => {
          this.spinnerService.hide();
        });

      },
      (error) => {
       this.spinnerService.hide();
      });

    },
    (error) => {
      this.spinnerService.hide();
    });
  }

  GetHeaderDetail() {
    this.service.get("HeaderDetail").subscribe(result => {
      let objs:any[];
      objs = result as any;
      this.mainHeader = objs.filter(s=>s.HeaderType == 'Main')[0].Name;
      this.ourServicesHeader = objs.filter(s=>s.HeaderType == 'OurServices')[0].Name;
      this.outReachHeader = objs.filter(s=>s.HeaderType == 'Outreach')[0].Name;
      this.ourApproachHeader = objs.filter(s=>s.HeaderType == 'OurApproach')[0].Name;
    },
    (error) => {
      this.spinnerService.hide();
    });
  }

  GetAboutUs() {
    this.service.get("AboutUs").subscribe(result => {
      let objs:any;
      objs = result as any;
      this.aboutUs = objs.Name;
    },
    (error) => {
     this.spinnerService.hide();
    });
  }

 

  GetImpactingLives() {
    this.service.get("ImpactingLives").subscribe(result => {
      let objs:any;
      objs = result as any;
      this.ImpactingLives = objs;
    },
    (error) => {
     this.spinnerService.hide();
    });
  }

  getTestimonial()
  {
    this.service.get("Testimonial").subscribe(result => {
      this.TestimonialList  = result as any;
    },
    (error) => {
     this.spinnerService.hide();
    });
  }

  getProjects()
  {
    this.service.get("Projects").subscribe(result => {
      this.projectDTO  = result as any;
    },
    (error) => {
      this.spinnerService.hide();
    });
  }

  getSocialLinks()
  {
    this.service.get("SocialLinks").subscribe(result => {
      this.SocialLinksList  = result as any;
      this.spinnerService.hide();
      countTimer();
    },
    (error) => {
      this.spinnerService.hide();
    });
  }

  getWhoWeWorkFor()
  {
    this.service.get("WhoWeWorkFor").subscribe(result => {
      this.whoWeWorkForDTO  = result as any;
    },
    (error) => {
     this.spinnerService.hide();
    });
  }

  getAward()
  {
    this.service.get("Awards").subscribe(result => {
      this.awardDTO  = result as any;
    },
    (error) => {
      this.spinnerService.hide();
    });
  }
}
