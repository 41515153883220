import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-resources',
  templateUrl: './our-resources.component.html',
  styleUrls: ['./our-resources.component.css']
})
export class OurResourcesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
