import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { FooterComponent } from './layout/footer/footer.component';
import { Header2Component } from './layout/header2/header2.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { OurApproachComponent } from './layout/our-approach/our-approach.component';
import { OurTeamComponent } from './layout/our-team/our-team.component';
import { OutreachComponent } from './layout/outreach/outreach.component';
import { OurResourcesComponent } from './layout/our-resources/our-resources.component';
import { OurServicesComponent } from './layout/our-services/our-services.component';
import { GalleryComponent } from './pages/gallery/gallery.component';
import { ProjectsComponent } from './layout/projects/projects.component';
import { ContactUsComponent } from './layout/contact-us/contact-us.component';
import { JoinUsComponent } from './layout/join-us/join-us.component';
import { ClientsComponent } from './layout/clients/clients.component';
import { TestimonialsComponent } from './layout/testimonials/testimonials.component';
import { SocialComponent } from './layout/social/social.component';
import { TeamComponent } from './pages/team/team.component';
import { ServicesComponent } from './pages/services/services.component';
import { ProjectsComponent2 } from './pages/projects/projects.component';
import { HttpClientModule } from '@angular/common/http';
import {AgGridModule} from "ag-grid-angular";

import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { TwitterComponent } from './core/component/twitter/twitter.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { ResourcesComponent } from './pages/resources/resources.component';
import { ButtonRendererComponent } from './core/shared/button-renderer.component';
import { AwardsComponent } from './layout/awards/awards.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomepageComponent,
    FooterComponent,
    Header2Component,
    AboutUsComponent,
    OurApproachComponent,
    OurTeamComponent,
    OutreachComponent,
    OurResourcesComponent,
    OurServicesComponent,
    GalleryComponent,
    ProjectsComponent,
    ContactUsComponent,
    JoinUsComponent,
    ClientsComponent,
    TestimonialsComponent,
    SocialComponent,
    TeamComponent,
    ServicesComponent,
    ProjectsComponent2,
    TwitterComponent,
    ResourcesComponent,
    ButtonRendererComponent,
    AwardsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AgGridModule.withComponents([ButtonRendererComponent]),
    BrowserAnimationsModule,
    Ng4LoadingSpinnerModule.forRoot()
  ],
  providers: [
    
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
